// Generated by Framer (b62ce54)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["JnZld42EX", "FVjFoVz3C"];

const variantClassNames = {FVjFoVz3C: "framer-v-lx5bdm", JnZld42EX: "framer-v-zyie6q"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 2": "FVjFoVz3C", Arrows: "JnZld42EX"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "JnZld42EX", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "JnZld42EX", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const isDisplayed = () => {
if (baseVariant === "FVjFoVz3C") return true
return false
}

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-GWHpr", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-zyie6q", className)} data-framer-name={"Arrows"} layoutDependency={layoutDependency} layoutId={"JnZld42EX"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({FVjFoVz3C: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><SVG className={"framer-115osdi"} data-framer-name={"Navigation / Arrow-left"} layout={"position"} layoutDependency={layoutDependency} layoutId={"Ola0s_Z7a"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 21 21\"><path d=\"M 3.51 9.801 L 3.51 11.485 L 13.611 11.485 L 8.982 16.115 L 10.177 17.31 L 16.844 10.643 L 10.177 3.977 L 8.982 5.172 L 13.611 9.801 Z\" fill=\"var(--token-e90779aa-d8c3-4a83-8241-79d1f486d2f7, rgb(255, 249, 243)) /* {&quot;name&quot;:&quot;web.neutral.light.100(base)&quot;} */\"></path></svg>"} svgContentId={2478552313} transition={transition} withExternalLayout/>{isDisplayed() && (<motion.div className={"framer-ak59ud"} data-framer-name={"Play"} layoutDependency={layoutDependency} layoutId={"eGxGwzJqE"} transition={transition}><SVG className={"framer-17xtn92"} data-framer-name={"Vector 1061"} fill={"rgba(0,0,0,1)"} intrinsicHeight={16} intrinsicWidth={14} layoutDependency={layoutDependency} layoutId={"I17852:851434;3400:1561"} svg={"<svg width=\"14\" height=\"16\" viewBox=\"0 0 14 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M13.9961 7.98774L0 0L0 16L13.9961 7.98774Z\" fill=\"black\"/>\n</svg>\n"} transition={transition} withExternalLayout/></motion.div>)}</motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-GWHpr [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-GWHpr .framer-1pyexxa { display: block; }", ".framer-GWHpr .framer-zyie6q { height: 21px; overflow: visible; position: relative; width: 21px; }", ".framer-GWHpr .framer-115osdi { flex: none; height: 21px; left: calc(50.00000000000002% - 21px / 2); position: absolute; top: calc(50.00000000000002% - 21px / 2); width: 21px; }", ".framer-GWHpr .framer-ak59ud { aspect-ratio: 1 / 1; bottom: -2px; flex: none; height: var(--framer-aspect-ratio-supported, 24px); left: -1px; overflow: visible; position: absolute; right: -2px; }", ".framer-GWHpr .framer-crc1sq { flex: none; height: 24px; left: 0px; position: absolute; top: 0px; width: 24px; }", ".framer-GWHpr .framer-17xtn92 { bottom: 4px; flex: none; left: 6px; position: absolute; right: 4px; top: 4px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 21
 * @framerIntrinsicWidth 21
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"FVjFoVz3C":{"layout":["fixed","fixed"]}}}
 */
const FramernVaEYbLFy: React.ComponentType<Props> = withCSS(Component, css, "framer-GWHpr") as typeof Component;
export default FramernVaEYbLFy;

FramernVaEYbLFy.displayName = "Right icon";

FramernVaEYbLFy.defaultProps = {height: 21, width: 21};

addPropertyControls(FramernVaEYbLFy, {variant: {options: ["JnZld42EX", "FVjFoVz3C"], optionTitles: ["Arrows", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramernVaEYbLFy, [])